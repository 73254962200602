<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色(基础权限)</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card shadow="never">
      <div class="change">
        <el-input v-model="queryInfo.roleName"
                  placeholder="请输入角色"
                  clearable></el-input>
        <el-button type="primary"
                   @click="search">查询</el-button>
        <el-button>导出</el-button>
        <el-button type="primary"
                   class="add"
                   @click="addButtonDialogVisible = true">新增</el-button>
      </div>
      <el-table :data="roleList"
                border
                stripe
                style="width: 100%"
                :header-cell-style="{background:'rgba(22, 119, 255, 0.0300)'}">
        <el-table-column type="index"
                         align="center">
        </el-table-column>
        <el-table-column prop="roleName"
                         label="角色名称"
                         align="center">
        </el-table-column>
        <el-table-column prop="flag"
                         label="状态"
                         align="center">
          <template slot-scope="scope">{{map1[scope.row.flag]}}</template>
        </el-table-column>

        <el-table-column label="操作"
                         align="center"
                         width="700">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-plus"
                       size="mini"
                       @click="addSetRightDialog(scope.row)">新增按钮权限</el-button>
            <el-button type="danger"
                       icon="el-icon-delete"
                       size="mini"
                       @click="removeSetRightDialog(scope.row)">删除按钮权限</el-button>
            <el-button type="info"
                       icon="el-icon-edit-outline"
                       size="mini"
                       @click="editSetRightDialog(scope.row)">编辑按钮权限</el-button>
            <el-button icon="el-icon-search"
                       size="mini"
                       @click="searchSetRightDialog(scope.row)">查询按钮权限</el-button>
            <el-button type="warning"
                       icon="el-icon-menu"
                       size="mini"
                       @click="showSetRightDialog(scope.row)">菜单权限</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.current"
                     :page-sizes="[10,20,50,100,200]"
                     :page-size="queryInfo.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加角色对话框 -->
    <el-dialog title="新增角色"
               :visible.sync="addButtonDialogVisible"
               width="40%"
               @close="closeDialog">
      <el-form ref="addFormRef"
               :model="addForm"
               :rules="addFormRules"
               label-width="80px">
        <el-form-item label="角色名称"
                      prop="roleName">
          <el-input v-model="addForm.roleName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="addRole">保 存</el-button>
        <el-button @click="addButtonDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 增加按钮权限对话框 -->
    <el-dialog title="新增按钮权限分配"
               :visible.sync="addDialogVisible"
               width="40%"
               @close="addDialogClosed">
      <!-- 树形控件 -->
      <el-tree :data="addList"
               :props="treeProps"
               show-checkbox
               node-key="id"
               :default-expand-all="true"
               :default-checked-keys="addDefkeys"
               ref="addTreeRef"></el-tree>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addRights">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除按钮权限对话框 -->
    <el-dialog title="删除按钮权限分配"
               :visible.sync="removeDialogVisible"
               width="40%"
               @close="removeDialogClosed">
      <!-- 树形控件 -->
      <el-tree :data="removeList"
               :props="treeProps"
               show-checkbox
               node-key="id"
               :default-expand-all="true"
               :default-checked-keys="removeDefkeys"
               ref="removeTreeRef"></el-tree>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="removeDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="removeRights">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑按钮权限对话框 -->
    <el-dialog title="编辑按钮权限分配"
               :visible.sync="editDialogVisible"
               width="40%"
               @close="editDialogClosed">
      <!-- 树形控件 -->
      <el-tree :data="editList"
               :props="treeProps"
               show-checkbox
               node-key="id"
               :default-expand-all="true"
               :default-checked-keys="editDefkeys"
               ref="editTreeRef"></el-tree>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="editRights">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查询按钮权限对话框 -->
    <el-dialog title="查询按钮权限分配"
               :visible.sync="searchDialogVisible"
               width="40%"
               @close="searchDialogClosed">
      <!-- 树形控件 -->
      <el-tree :data="searchList"
               :props="treeProps"
               show-checkbox
               node-key="id"
               :default-expand-all="true"
               :default-checked-keys="searchDefkeys"
               ref="searchTreeRef"></el-tree>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="searchDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="searchRights">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 菜单分配权限对话框 -->
    <el-dialog title="菜单权限分配"
               :visible.sync="setRightDialogVisible"
               width="40%"
               @close="setRightDialogClosed">
      <!-- 树形控件 -->
      <el-tree :data="rightsList"
               :props="treeProps"
               show-checkbox
               node-key="id"
               :default-expand-all="true"
               :default-checked-keys="menuDefkeys"
               ref="treeRef"></el-tree>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="setRightDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="allotRights">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      // 控制添加角色对话框的显示与隐藏
      addButtonDialogVisible: false,
      // 控制新增分配权限对话框的显示与隐藏
      addDialogVisible: false,
      // 控制编辑分配权限对话框的显示与隐藏
      removeDialogVisible: false,
      // 控制编辑分配权限对话框的显示与隐藏
      editDialogVisible: false,
      // 控制查询分配权限对话框的显示与隐藏
      searchDialogVisible: false,
      // 控制菜单分配权限对话框的显示与隐藏
      setRightDialogVisible: false,
      //   角色列表
      roleList: [],
      // 所有新增菜单角色权限的数据为空
      addList: [],
      // 所有删除菜单角色权限的数据为空
      removeList: [],
      // 所有编辑菜单角色权限的数据为空
      editList: [],
      // 所有查询菜单角色权限的数据为空
      searchList: [],
      // 所有菜单角色权限的数据为空
      rightsList: [],
      queryInfo: {
        roleName: '',
        size: 10,
        current: 1,
      },
      // 添加表单的验证规则对象
      addFormRules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
      },
      total: 0,
      map1: ['禁用', '启用'],
      // 添加角色表单数据
      addForm: {
        roleName: '',
      },
      // 树形控件的属性绑定对象
      treeProps: {
        label: 'menuName',
        children: 'subMenu',
      },
      // 菜单权限分配选中节点id值数组
      menuDefkeys: [],
      // 增加权限按钮分配选中节点id值数组
      addDefkeys: [],
      // 删除权限按钮分配选中节点id值数组
      removeDefkeys: [],
      // 增编辑权限按钮分配选中节点id值数组
      editDefkeys: [],
      // 查询权限按钮分配选中节点id值数组
      searchDefkeys: [],
      roleId: '',
    }
  },
  // 钩子   创建后执行此函数
  created() {
    this.getRoleList()
  },
  methods: {
    // 获取角色列表
    getRoleList() {
      this.axios.post('/api/site/role/page', this.queryInfo).then((res) => {
        // console.log(res)
        this.roleList = res.data.data.records
        this.total = res.data.data.total
      })
    },
    // 搜索框
    search() {
      this.queryInfo.current = 1
      this.getRoleList
    },
    // 监听一页有几条数据
    handleSizeChange(newSize) {
      //   console.log(newSize)
      this.queryInfo.size = newSize
      this.getRoleList()
    },
    // 监听第几页
    handleCurrentChange(newPage) {
      // console.log(newPage);
      this.queryInfo.current = newPage
      this.getRoleList()
    },
    // 监听添加角色对话框关闭重置
    closeDialog() {
      this.$refs.addFormRef.resetFields()
    },
    // 点击添加新角色
    addRole() {
      this.$refs.addFormRef.validate((valid) => {
        // console.log(valid)
        if (!valid) return
        this.axios
          .get(`/api/site/role/add?roleName=${this.addForm.roleName}`)
          .then((res) => {
            // console.log(res)
            this.$message({
              message: '添加成功',
              type: 'success',
              duration: 1000,
            })
            // 重新获取角色列表
            this.getRoleList()
            // 点击确认关闭
            this.addButtonDialogVisible = false
          })
      })
    },

    // 展示新增按钮权限对话框
    addSetRightDialog(role) {
      this.roleId = role.id
      this.axios
        .get(`/api/site/role/getRights?roleId=${role.id}&type=${2}`)
        .then((res) => {
          //   console.log(res)
          this.addList = res.data.data
          this.addList.forEach((item) => {
            // console.log(item)
            item.subMenu.forEach((i) => {
              //   console.log(i)
              if (i.hasMenu == true && i.menuState == 1) {
                return this.addDefkeys.push(i.id)
              }
            })
          })
        })
      this.addDialogVisible = true
    },
    // 监听新增按钮权限对话框的关闭事件
    addDialogClosed() {
      this.addDefkeys = []
    },
    addRights() {
      const keys = [
        ...this.$refs.addTreeRef.getCheckedKeys(),
        ...this.$refs.addTreeRef.getHalfCheckedKeys(),
      ]
      //   console.log(keys)
      const idStr = keys.join(',')
      this.axios
        .post('/api/site/role/setRights', {
          rights: idStr,
          roleId: this.roleId,
          type: 2,
        })
        .then((res) => {
          this.$message({
            message: '新增按钮权限分配成功',
            type: 'success',
            duration: 1000,
          })
          this.getRoleList()
          this.addDialogVisible = false
        })
    },

    // 展示删除按钮权限对话框
    removeSetRightDialog(role) {
      this.roleId = role.id
      this.axios
        .get(`/api/site/role/getRights?roleId=${role.id}&type=${3}`)
        .then((res) => {
          //   console.log(res)
          this.removeList = res.data.data
          this.removeList.forEach((item) => {
            // console.log(item)
            item.subMenu.forEach((i) => {
              //   console.log(i)
              if (i.hasMenu == true && i.menuState == 1) {
                return this.removeDefkeys.push(i.id)
              }
            })
          })
        })
      this.removeDialogVisible = true
    },
    // 监听删除按钮权限对话框的关闭事件
    removeDialogClosed() {
      this.removeDefkeys = []
    },
    removeRights() {
      const keys = [
        ...this.$refs.removeTreeRef.getCheckedKeys(),
        ...this.$refs.removeTreeRef.getHalfCheckedKeys(),
      ]
      //   console.log(keys)
      const idStr = keys.join(',')
      this.axios
        .post('/api/site/role/setRights', {
          rights: idStr,
          roleId: this.roleId,
          type: 3,
        })
        .then((res) => {
          this.$message({
            message: '删除按钮权限分配成功',
            type: 'success',
            duration: 1000,
          })
          this.getRoleList()
          this.removeDialogVisible = false
        })
    },
    // 展示编辑按钮权限对话框
    editSetRightDialog(role) {
      this.roleId = role.id
      this.axios
        .get(`/api/site/role/getRights?roleId=${role.id}&type=${4}`)
        .then((res) => {
          //   console.log(res)
          this.editList = res.data.data
          this.editList.forEach((item) => {
            // console.log(item)
            item.subMenu.forEach((i) => {
              //   console.log(i)
              if (i.hasMenu == true && i.menuState == 1) {
                return this.editDefkeys.push(i.id)
              }
            })
          })
        })
      this.editDialogVisible = true
    },
    // 监听编辑按钮权限对话框的关闭事件
    editDialogClosed() {
      this.editDefkeys = []
    },
    editRights() {
      const keys = [
        ...this.$refs.editTreeRef.getCheckedKeys(),
        ...this.$refs.editTreeRef.getHalfCheckedKeys(),
      ]
      //   console.log(keys)
      const idStr = keys.join(',')
      this.axios
        .post('/api/site/role/setRights', {
          rights: idStr,
          roleId: this.roleId,
          type: 4,
        })
        .then((res) => {
          this.$message({
            message: '编辑按钮权限分配成功',
            type: 'success',
            duration: 1000,
          })
          this.getRoleList()
          this.editDialogVisible = false
        })
    },
    // 展示查找按钮权限对话框
    searchSetRightDialog(role) {
      this.roleId = role.id
      this.axios
        .get(`/api/site/role/getRights?roleId=${role.id}&type=${5}`)
        .then((res) => {
          //   console.log(res)
          this.searchList = res.data.data
          this.searchList.forEach((item) => {
            // console.log(item)
            item.subMenu.forEach((i) => {
              //   console.log(i)
              if (i.hasMenu == true && i.menuState == 1) {
                return this.searchDefkeys.push(i.id)
              }
            })
          })
        })
      this.searchDialogVisible = true
    },
    // 监听查找按钮权限对话框的关闭事件
    searchDialogClosed() {
      this.searchDefkeys = []
    },
    searchRights() {
      const keys = [
        ...this.$refs.searchTreeRef.getCheckedKeys(),
        ...this.$refs.searchTreeRef.getHalfCheckedKeys(),
      ]
      //   console.log(keys)
      const idStr = keys.join(',')
      this.axios
        .post('/api/site/role/setRights', {
          rights: idStr,
          roleId: this.roleId,
          type: 5,
        })
        .then((res) => {
          this.$message({
            message: '查询按钮权限分配成功',
            type: 'success',
            duration: 1000,
          })
          this.getRoleList()
          this.searchDialogVisible = false
        })
    },

    // 展示菜单权限对话框
    showSetRightDialog(role) {
      this.roleId = role.id
      this.axios
        .get(`/api/site/role/getRights?roleId=${role.id}&type=${1}`)
        .then((res) => {
          //   console.log(res)
          this.rightsList = res.data.data
          this.rightsList.forEach((item) => {
            // console.log(item)
            item.subMenu.forEach((i) => {
              //   console.log(i)
              if (i.hasMenu == true && i.menuState == 1) {
                return this.menuDefkeys.push(i.id)
              }
            })
          })
        })
      this.setRightDialogVisible = true
    },
    // 监听分配菜单权限对话框的关闭事件
    setRightDialogClosed() {
      this.menuDefkeys = []
    },
    allotRights() {
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys(),
      ]
      //   console.log(keys)
      const idStr = keys.join(',')
      this.axios
        .post('/api/site/role/setRights', {
          rights: idStr,
          roleId: this.roleId,
          type: 1,
        })
        .then((res) => {
          this.$message({
            message: '权限分配成功',
            type: 'success',
            duration: 1000,
          })
          this.getRoleList()
          this.setRightDialogVisible = false
        })
    },
  },
  // 搜索数据列表跟随变化
  watch: {
    queryInfo: {
      handler: function (newVal) {
        // console.log(newVal.queryInfo)
        this.getRoleList()
      },
      deep: true,
    },
  },
}
</script>
    <style scoped lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}
.el-table {
  margin-top: 20px;
}
.el-input {
  margin-right: 8px;
  width: 240px;
}
.el-card {
  margin-bottom: 20px;
}
.change {
  margin: 20px 0;
  .add {
    width: 86px;
    margin-left: 50px;
  }
}
.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>
    
  